import React, { useState } from 'react';
import Input from '../../common/components/Input';
import { __ } from '../../common/utils/LanguageHelper';
import { Link, navigate, useIntl } from 'gatsby-plugin-intl';
import FullButton from '../FullButton';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { trimObjValues } from '../../common/utils/functions';

const Step2 = ({ formValues, passData, loginUrl }) => {
    const [serverErrors, setServerErrors] = useState({});
    const [generalError, setGeneralError] = useState('');
    const { locale } = useIntl();

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .email(
                __(
                    'ادخل بريد الكتروني صالح مثال: you@example.com',
                    'Enter valid email EX: you@example.com'
                )
            )
            .required(__('ادخل البريد الإلكتروني', 'Enter your email')),
        password: Yup.string()
            .required(__('ادخل كلمة المرور', 'Enter your password'))
            .min(
                8,
                __(
                    'يجب ان تحتوي كلمة المرور على الأقل 8 أرقام',
                    'Password must contain at least 8 digits'
                )
            )
            .max(
                255,
                __(
                    'يحب أن لا تزيد كلمة المرور عن 255 حرف',
                    'Password has a maximum limit of 255 characters'
                )
            ),
    });

    return (
        <>
            <Formik
                initialValues={{
                    username: formValues.username,
                    password: formValues.password,
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    let data = trimObjValues(values);
                    const tenant_id = localStorage.getItem('tenant_id');

                    try {
                        const res = await axios.post(
                            `${loginUrl}&lang=${locale}`,
                            {
                                ...data,
                                tenant_id,
                                client_id: 2,
                                client_secret:
                                    'RYcpMUeSytqafrMJF48Tko0VOazXSxj8ACBIqs8t',
                                grant_type: 'password',
                                scope: '',
                            }
                        );
                        if (res.status === 200) {
                            localStorage.setItem(
                                'access_token',
                                res.data.access_token
                            );
                            localStorage.setItem('user_email', data.username);
                            return navigate('/');
                        }
                    } catch (error) {
                        if (error.response.status === 500) {
                            return navigate('/500');
                        } else if (error.response.status === 400) {
                            setGeneralError(
                                locale === 'ar'
                                    ? 'اسم المستخدم أو كلمة المرور غير صحيحة'
                                    : 'incorrect username or password'
                            );
                        } else if (error.response.data.errors) {
                            setServerErrors(error.response.data.errors);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {generalError && (
                            <p className="error-message">{generalError}</p>
                        )}

                        <Input
                            inputType="email"
                            name="username"
                            label={__('البريد الإلكتروني', 'Email Address')}
                            placeholder="you@example.com"
                            value={values.username}
                            onChange={(val) => {
                                handleChange(val);
                                passData(values);
                            }}
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="username"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.username && (
                                        <p className="error-message">
                                            {serverErrors.username}
                                        </p>
                                    )}
                                </>
                            }
                            className={`input ${
                                (serverErrors.username || errors.username) &&
                                touched.username &&
                                'input-error'
                            }`}
                        />

                        <Input
                            inputType="password"
                            name="password"
                            label={__('كلمة المرور', 'Password')}
                            value={values.password}
                            onChange={(val) => {
                                handleChange(val);
                                passData(values);
                            }}
                            placeholder={__(
                                'ادخل كلمة المرور',
                                "What's your password?"
                            )}
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.email && (
                                        <p className="error-message">
                                            {serverErrors.email}
                                        </p>
                                    )}
                                </>
                            }
                            className={`input ${
                                (serverErrors.password || errors.password) &&
                                touched.password &&
                                'input-error'
                            }`}
                        />

                        <h4 className="forget-password-label">
                            {__(
                                <Link to="/forget-password">
                                    هل نسيت كلمة المرور؟
                                </Link>,
                                <Link to="/forget-password">
                                    Forget password?
                                </Link>
                            )}
                        </h4>

                        <FullButton
                            disabled={isSubmitting}
                            type="submit"
                            content={
                                isSubmitting
                                    ? __('جاري التسجيل...', 'Login...')
                                    : __('تسجيل', 'Login')
                            }
                            onClick={() => handleSubmit()}
                        />
                    </form>
                )}
            </Formik>

            <h4 className="toggleLink">
                {__(
                    <>
                        ليس لديك حساب؟ <Link to="/signup">إنشاء حساب</Link>
                    </>,
                    <>
                        Don't have an account? <Link to="/signup">Sign Up</Link>
                    </>
                )}
            </h4>
        </>
    );
};

export default Step2;
