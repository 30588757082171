export function trimObjValues(obj) {
    return Object.keys(obj).reduce((acc, curr) => {
        if (typeof obj[curr] === 'string') {
            acc[curr] = obj[curr].trim();
        } else {
            acc[curr] = obj[curr];
        }
        return acc;
    }, {});
}
