import React from 'react';
import { __ } from '../../common/utils/LanguageHelper';
import DomainCardWrapper from './DomainCard.style';

const DomainCard = ({ domainName, onClick }) => {
    return (
        <DomainCardWrapper>
            <div>
                <h2>{__('عنوان نطاقك', 'Your domain name')}</h2>
                <p onClick={onClick}>{__('تعديل', 'Edit')}</p>
            </div>
            <span>{domainName}</span>
        </DomainCardWrapper>
    );
};

export default DomainCard;
