import styled from 'styled-components';

const DomainCardWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: #fff;
    border: 4px solid transparent;
    background-clip: padding-box;
    flex-direction: column;
    margin-bottom: 20px;

    &::after {
        content: '';
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        background: linear-gradient(to left, #743ad5, #d53a9d);
        z-index: -1;
        border-radius: 10px;
    }

    & > div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        & > h2 {
            font-size: 22px;
            font-weight: 400;
            margin: 0;
        }

        & > p {
            margin: 0;
            font-size: 17px;
            color: #35459e;
            font-weight: bold;
            cursor: pointer;
        }
    }

    & > span {
        font-size: 18px;
    }
`;

export default DomainCardWrapper;
