import React, { Component } from 'react';
import FormContainer from '../FormContainer';
import Step1 from './Step1';
import Step2 from './Step2';
import DomainCard from '../DomainCard';
import { LoginProvider } from '../../common/contexts/LoginContext';
import { Link } from 'gatsby-plugin-intl';
import Image from '../../common/components/Image';
import LogoImage from '../../common/assets/image/logo.png';

class LoginForm extends Component {
    state = {
        form: {
            domain: '',
            username: '',
            password: '',
        },
        isStep2: false,
        loginUrl: '',
    };

    handleCallback = (data) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                ...data,
            },
        }));
    };

    setLoginUrl = (url) => {
        this.setState({ loginUrl: url });
    };

    setStep2 = () => {
        this.setState({ isStep2: true });
    };

    render() {
        return (
            <LoginProvider>
                <FormContainer>
                    <Link to="/">
                        <Image
                            src={LogoImage}
                            alt="Awasul logo"
                            className="left-side__form__logo"
                        />
                    </Link>

                    <h1 className="title">
                        {this.props.lang === 'ar'
                            ? 'ستقوم بالتسجيل إلى نطاق شركتك'
                            : 'You will register to your company domain'}
                    </h1>

                    {this.state.isStep2 ? (
                        <>
                            <DomainCard
                                domainName={this.state.form.domain}
                                onClick={() =>
                                    this.setState({ isStep2: false })
                                }
                            />
                            <Step2
                                formValues={this.state.form}
                                loginUrl={this.state.loginUrl}
                                passData={this.handleCallback}
                            />
                        </>
                    ) : (
                        <Step1
                            passData={this.handleCallback}
                            domainName={this.state.form.domain}
                            setLoginUrl={this.setLoginUrl}
                            setStep2={this.setStep2}
                        />
                    )}
                </FormContainer>
            </LoginProvider>
        );
    }
}

export default LoginForm;
