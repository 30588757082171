import React, { useReducer } from 'react';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DOMAIN':
            return {
                domain: action.payload,
            };
        default:
            return state;
    }
}
export const LoginContext = React.createContext({});

export const LoginProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, { domain: '' });
    return (
        <LoginContext.Provider value={{ state, dispatch }}>
            {children}
        </LoginContext.Provider>
    );
};
