import React, { useState, useEffect } from 'react';
import Input from '../../common/components/Input';
import { __ } from '../../common/utils/LanguageHelper';
import FullButton from '../FullButton';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useIntl } from 'gatsby-plugin-intl';
import axios from 'axios';
import { domainPattern } from '../../common/utils/globals';
import { trimObjValues } from '../../common/utils/functions';

const Step1 = ({ domainName, passData, setStep2, setLoginUrl }) => {
    const [serverErrors, setServerErrors] = useState('');
    const [domain, setDomain] = useState('');
    const { locale } = useIntl();

    const LoginSchema = Yup.object().shape({
        domain: Yup.string()
            .trim()
            .matches(
                domainPattern,
                __('اسم النطاق غير صالح', 'Invalid Domain name')
            )
            .min(
                3,
                __(
                    'اسم النطاق يجب ان يحتوي علي الاقل 3 احرف',
                    'Domain name must be at least 3 characters'
                )
            )
            .max(
                20,
                __(
                    'يحب ان لا يزيد اسم النطاق عن 20 حرف',
                    'Domain name has a maximum limit of 20 characters'
                )
            )
            .required(__('ادخل اسم النطاق', 'Enter the domain name')),
    });

    useEffect(() => {
        setDomain(localStorage.getItem('domain'));
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    domain: domainName,
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    let trim_values = trimObjValues(values);
                    trim_values.domain = trim_values.domain.toLowerCase();

                    const res = await axios.get(
                        `https://api.awasul1.com/v1/domain/check?domain=${trim_values.domain}`
                    );
                    if (res.data.status === 'success') {
                        passData(trim_values);
                        localStorage.setItem('tenant_id', res.data.tenant_id);
                        localStorage.setItem('domain', trim_values.domain);
                        setLoginUrl(res.data.endpoint);
                        setStep2();
                    } else {
                        setServerErrors(
                            locale === 'ar'
                                ? 'اسم النطاق غير مسجل'
                                : 'The domain is not registered'
                        );
                    }
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => {
                    if (domain) {
                        values.domain = domain;
                    }
                    return (
                        <form onSubmit={handleSubmit} name="login_step1_form">
                            <Input
                                inputType="text"
                                ltr
                                staticValue="awasul1.com."
                                name="domain"
                                label={__('اسم النطاق', 'Domain name')}
                                placeholder="Ex: energize"
                                value={domain || values.domain}
                                onChange={(e) => {
                                    if (domain) {
                                        setDomain('');
                                    }
                                    handleChange(e);
                                }}
                                errorMessage={
                                    <>
                                        <ErrorMessage
                                            name="domain"
                                            component="div"
                                            className="error-message"
                                        />
                                        {serverErrors && (
                                            <p className="error-message">
                                                {serverErrors}
                                            </p>
                                        )}
                                    </>
                                }
                                className={`input ${
                                    (serverErrors || errors.domain) &&
                                    touched.domain &&
                                    'input-error'
                                }`}
                            />

                            <FullButton
                                disabled={isSubmitting}
                                type="submit"
                                content={
                                    isSubmitting
                                        ? __('جاري التحقق ...', 'Verifying ...')
                                        : __('التالي', 'Login')
                                }
                                onClick={() => {
                                    handleSubmit();
                                }}
                            />
                            <h4 className="toggleLink">
                                {__(
                                    <>
                                        ليس لديك حساب؟
                                        <Link to="/signup"> إنشاء حساب</Link>
                                    </>,
                                    <>
                                        Don't have an account?{' '}
                                        <Link to="/signup"> Sign Up</Link>
                                    </>
                                )}
                            </h4>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default Step1;
