import React from 'react';
import FormWrapper from './FormContainer.style';
import { __ } from '../../common/utils/LanguageHelper';
import Image from '../../common/components/Image';
import LogoImage from '../../common/assets/image/logo.png';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import { IconButton } from '@material-ui/core';
import { Link, useIntl } from 'gatsby-plugin-intl';

export default function FormContainer({ children, ...rest }) {
    const socialMediaList = [
        {
            icon: <TwitterIcon />,
            url: 'https://twitter.com/awasul1',
        },
        {
            icon: <InstagramIcon />,
            url: 'https://www.instagram.com/awasul1',
        },
        {
            icon: <LinkedInIcon />,
            url: 'https://www.linkedin.com/company/awasul/',
        },
        {
            icon: <LanguageIcon />,
            url: 'http://awasul1.com/',
        },
    ];

    const RenderSocialMedia = () => (
        <div className="socialIconContainer" lang={locale}>
            {socialMediaList.map(({ icon, url }, i) => (
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                    key={i + 1}
                >
                    <IconButton className="socialIcon">
                        {icon}
                    </IconButton>
                </a>
            ))}
        </div>
    )

    const { locale } = useIntl();

    return (
        <FormWrapper {...rest}>
            <div className="right-side">
                <Link to="/">
                    <Image src={LogoImage} alt="Awasul logo" />
                </Link>

                <div
                    style={{
                        marginTop: '150px',
                    }}
                >
                    <h1 className="right-side__title">
                        {__('مرحبا بك في أواصل', 'Welcome to Awasul.')}
                    </h1>
                    <p className="right-side__description">
                        {__(
                            'أول منصة تواصل داخلي(انترانت) و أول منتج اجتماعي سعودي عربي موجه إلى كافة المنظمات الحكومية والخاصة , والتي تمكن موظفي المنشآت من التواصل الداخلي والعمل عن بعد مع كافة منسوبيها حول العالم بأمان وسهولة.',
                            'The first internal communication platform (Intranet) and the first Saudi Arabian e-social product addressed to all governmental and private organizations, which enables staff member to communicate internally and to work remotely with all its users around the world safely and easily.'
                        )}
                    </p>
                </div>
            </div>

            <div className="left-side">
                <Link
                    to="/"
                    className="arrow-link"
                    style={{
                        color: '#000',
                    }}
                >
                    <ArrowRightAltIcon
                        className="arrow-link__icon"
                        style={{
                            marginLeft: 5,
                            transform: locale !== 'ar' && 'rotate(180deg)',
                        }}
                    />
                    <p className="arrow-link__title">{__('رجوع', 'Back')}</p>
                </Link>

                <div className="left-side__form">
                    {children}
                    <RenderSocialMedia/>
                </div>
            </div>
        </FormWrapper>
    );
}
