import styled from 'styled-components';
import AwasulStoryImage from '../../common/assets/image/DSC_6853.png';

const FormWrapper = styled.div`
    display: flex;
    background-size: cover;
    overflow: auto;

    .right-side {
        width: 50%;
        height: 100vh;
        padding: 50px 50px;
        background: linear-gradient(
                0deg,
                rgba(87, 107, 219, 0.88),
                rgba(129, 130, 224, 0.92)
            ),
            url(${AwasulStoryImage});
        background-size: cover;
        background-position: center;

        @media (max-width: 1300px) {
            width: 40%;
        }

        @media (max-width: 1000px) {
            display: none;
        }

        &__title {
            color: white;
            font-size: 50px;
            font-weight: bold;
            margin: 10px 0;
            @media (max-width: 1300px) {
                font-size: 40px;
            }
        }

        &__description {
            color: white;
            font-size: 17px;
            text-align: justify;
            margin: 0;
            opacity: 0.8;
            @media (max-width: 1300px) {
                font-size: 15px;
            }
        }
    }

    .socialIconContainer {
        position: absolute;
        bottom: 30px;
        right: 30px;
        left: 30px;
        max-width: 500px;

        @media (max-width: 1000px) {
            position: relative;
            bottom: 0;
            right: 0;
            left: 0;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    .socialIcon {
        color: #2c3880;
        background-color: #fff;
        padding: 10px;
        margin: 8px;
        transition: all 0.3s ease;
        &:hover {
            background-color: #5c6edc;
            color: #fff;
        }
    }

    .forget-password-label {
        margin-top: -20px;
        font-size: 15px;
    }

    .left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;

        @media (max-width: 1300px) {
            width: 60%;
        }

        @media (max-width: 1000px) {
            width: 100%;
        }

        .arrow-link {
            padding: 30px 30px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &__icon {
                font-size: 28px;
            }

            &__title {
                margin: 0;
                font-size: 22px;
            }
        }

        &__form {
            padding: 0 20% 0 20%;

            @media (max-width: 896px) {
                padding: 0 10% 0 10%;
            }

            &__logo {
                display: none;
                max-width: 125px;
                @media (max-width: 1000px) {
                    display: block;
                }
            }
        }
    }

    .title {
        font-size: 26px;
        color: #0f2137;
        margin: 10px 0 25px 0;
    }

    .toggleLink {
        margin: 25px 0 0 0;
    }
`;

export default FormWrapper;
